import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./GlobalCardCollage.css"

const GlobalCardCollage = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    // const businessDetailImagesImg = businessDetailImages && businessDetailImages.screenBannerImg;

    const screenGlobalCardCollageRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenGlobalCardCollage = screenGlobalCardCollageRef.current.getBoundingClientRect();
            const top = screenGlobalCardCollage.top;
            const bottom = screenGlobalCardCollage.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenGlobalCardCollage: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenGlobalCardCollage: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: {
                src: "",
                alt: ""
            }
        },
        shield: {

        },
        content: {
            listMember: [
                {
                    title: "E-Voa",
                    desc: "Electronic visa on arrival"
                },
                {
                    title: "Business Visa D-12",
                    desc: "Multiple entries  6 month stay - valid 1 year"
                },
                {
                    title: "Company",
                    desc: "Company (PT PMA)"
                },
                {
                    title: "KITAS",
                    desc: "Investor KITAS + Family KITAS"
                },
                {
                    title: "Remote Worker",
                    desc: "E33G Remote Worker."
                },
                {
                    title: "Retirement",
                    desc: "Retirement Visa"
                },
                {
                    title: "Entertainment",
                    desc: "Entertainment Visas"
                },
                {
                    title: "Extensions",
                    desc: "Visas and KITAS extensions"
                }
            ]
        }
    }
    
    return(
        <>
            <div 
            ref={screenGlobalCardCollageRef} 
            className={`${windowWidthClass}-global-card-collage wrapper`}>

                <div className="background">
                    <div className="frame">
                        {/* <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        /> */}
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                    {/* <h1>Top Sales</h1> */}
                    <div className="wrapper collage">
                        
                        {
                            componentData.content.listMember.map((member:any, index:any)=>{
                                const {title, desc } = member;
                                return (
                                    <div className="item">
                                        <div className="card">
                                            <h2>{title}</h2>
                                            <p>{desc}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
        
    )
};

export default GlobalCardCollage;