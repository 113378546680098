import { ImgHomeScreen1, ImgHomeScreen1Tab } from "../../assets";
import { AppFooter, AppNavbar } from "../../components";
import { GlobalConveyorScreen, GlobalHeaderScreen, HomeValuesScreen } from "../../containers";

const Home = () => {

    const data = {
        title: "One Stop Solution for Business and Personal",
        image: {
            src: ImgHomeScreen1,
            alt: ""
        },
        imageTab: {
            src: ImgHomeScreen1Tab,
            alt: ""
        }
    }

    return (
        <div className="home">
            <AppNavbar/>
            <GlobalHeaderScreen 
                data={data}
            />
            <HomeValuesScreen/>
            <GlobalConveyorScreen/>
            <AppFooter/>
        </div>
    )
};

export default Home;