import { ImgContactsScreen1, ImgContactsScreen1Tab } from "../../assets";
import { AppFooter, AppNavbar } from "../../components";
import { ContactDetailScreen, GlobalHeaderScreen } from "../../containers";

const Contacts = () => {

    const data = {
        title: "Contact Us",
        desc: "We're here to help! Whether you're curious about our services or want to chat about a potential project, contact us here. We can't wait to connect and see how we can help you reach your goals",
        image: {
            src: ImgContactsScreen1,
            alt: ""
        },
        imageTab: {
            src: ImgContactsScreen1Tab,
            alt: ""
        }
    }

    return (
        <div className="Contacts">
            <AppNavbar/>
            <GlobalHeaderScreen 
                data={data}
            />
            <ContactDetailScreen/>
            <AppFooter/>
        </div>
    )
};

export default Contacts;