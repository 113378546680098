import { ImgServicesScreen1, ImgServicesScreen1Tab } from "../../assets";
import { AppFooter, AppNavbar } from "../../components";
import { GlobalHeaderScreen, ServiceSubmenuScreen } from "../../containers";

const Services = () => {

    const data = {
        title: "Our Services",
        desc: "Our service portfolio offers top-tier expertise, including comprehensive legal solutions and management. We deliver complete property development and management services, alongside business development solutions designed to empower companies in today’s competitive market.",
        image: {
            src: ImgServicesScreen1,
            alt: ""
        },
        imageTab: {
            src: ImgServicesScreen1Tab,
            alt: ""
        }
    }

    return (
        <div className="Services">
            <AppNavbar/>
            <GlobalHeaderScreen 
                data={data}
            />
            <ServiceSubmenuScreen/>
            <AppFooter/>
        </div>
    )
};

export default Services;