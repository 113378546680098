import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./GlobalSubmenuDetail.css";

const GlobalSubmenuDetail = ({data}:any) =>{

    const code = data && data.code || "";
    const context = globalStates && globalStates.globalContext;
    const webData = globalStates && globalStates.webData;
    const componentContent = webData && webData.subservices[code];

    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const screenGlobalSubmenuRef = useRef(null) as any;
    // console.log({})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenGlobalSubmenu = screenGlobalSubmenuRef.current.getBoundingClientRect();
            const top = screenGlobalSubmenu.top;
            const bottom = screenGlobalSubmenu.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetscreenGlobalSubmenu: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetscreenGlobalSubmenu: true,
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [globalContext])

    const componentData = {
        background: {
            image: {
                src: "",
                alt: ""
            }
        },
        shield: {

        },
        content: componentContent || {
            title: code,
            desc: "Brief description about the service that is provided here, lorem ipsum dolor.",
            image: {
                src: "",
                alt: ""
            },
            listDetails: [
                {
                    title: "LOREM IPSUM",
                    icon: {
                        src: "",
                        alt: ""
                    },
                    descs: [
                        "lorem ipsum detail 1",
                        "lorem ipsum detail 2",
                        "lorem ipsum detail 3",
                        "lorem ipsum detail 4",
                        "lorem ipsum detail 5"
                    ]
                },
                {
                    title: "LOREM IPSUM",
                    icon: {
                        src: "",
                        alt: ""
                    },
                    descs: [
                        "lorem ipsum detail 1",
                        "lorem ipsum detail 2",
                        "lorem ipsum detail 3",
                        "lorem ipsum detail 4",
                        "lorem ipsum detail 5"
                    ]
                },{
                    title: "LOREM IPSUM",
                    icon: {
                        src: "",
                        alt: ""
                    },
                    descs: [
                        "lorem ipsum detail 1",
                        "lorem ipsum detail 2",
                        "lorem ipsum detail 3",
                        "lorem ipsum detail 4",
                        "lorem ipsum detail 5"
                    ]
                },
                {
                    title: "LOREM IPSUM",
                    icon: {
                        src: "",
                        alt: ""
                    },
                    descs: [
                        "lorem ipsum detail 1",
                        "lorem ipsum detail 2",
                        "lorem ipsum detail 3",
                        "lorem ipsum detail 4",
                        "lorem ipsum detail 5"
                    ]
                }
            ]
        }
    }
    
    return(
        <>
            <div 
            ref={screenGlobalSubmenuRef} 
            className={`${windowWidthClass}-global-submenu-detail wrapper`}>

                <div className="background">
                    <div className="frame">
                        <img
                            alt = {componentData.background.image.alt}
                            src = {componentData.background.image.src}
                        />
                    </div>
                </div>
                <div className="shield">

                </div>
                <div className="content display">
                    <div className="section header">
                        <div className="sub text-wrapper">
                            <h2>{componentData.content.title}</h2>
                            <p>{componentData.content.desc}</p>
                        </div>
                        <div className="sub frame">
                            <img 
                                src={componentData.content.image.src}
                                alt={componentData.content.image.alt}
                            />   
                        </div>
                    </div>
                    <div className="section cardholder">
                        {
                            componentData.content.listDetails.map((detail:any, index:any)=>{
                                const {title, icon, descs} =  detail;

                                return (
                                    <div key={index} className="card">
                                        <div className="sub frame">
                                            <img 
                                                src={icon.src}
                                                alt={icon.alt}
                                            />
                                        </div>
                                        <div className="sub text-wrapper">
                                            <h2>{title.toUpperCase()}</h2>
                                            {
                                                descs.map(
                                                    (desc:any, index:any)=>{
                                                        return (
                                                            <p key={index}>{desc}</p>
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

            </div>
        </>
        
    )
};

export default GlobalSubmenuDetail;